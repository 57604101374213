.tesauro-siis-logo {
    height: 80px;
}

.app-bar {
    position:static;
    background-color: #FBFBFB;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 3px solid #e7e7e7;
}

.btn-manual {
    display: flex;
    align-items: center;
    padding: 10px 15px;
}

.book-manual {
    height: 15px;
    margin-right: 5px;
}

@media (max-width: 599px) {
    .tesauro-siis-logo {
        height: 75px;
    }
}

#menu-appbar {
    width: 180px;
}