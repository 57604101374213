.top-bar-container {
  height: 40px;
  width: 100%;
  padding: 0 25px 0 20px;
  background-color: #3366CC;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo-top-bar {
  height: 20px;
  width: 90px;
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: auto 100%;
}
.link-gov {
  color: white;
  text-decoration: underline;
}

.link-gov:hover {
  color: white;
  text-decoration: none;
}
