* {
    font-family: "Work Sans", sans-serif;
    box-sizing: border-box;
}

body {
    margin: 0px !important;
    overflow-x: hidden;
}

em {
    cursor: pointer;
    background: #d1ffe3;
    font-style: normal;
}

em:hover {
    background: #d1ffe3;
    font-weight: bold;
}

.div_center {
    justify-content: center;
    align-content: center;
    display: flex;
    padding-top: 2%;
}

.div_flex_row {
    display: flex;
    flex-direction: row;
}

.btn_primary {
    background: #3366cc;
    border-radius: 30px;
    color: #ffffff;
    margin-left: 1%;
    padding: 0.4% 1%;
    border: none;
    height: 30px;
    text-transform: uppercase;
    cursor: pointer;
}

.btn_secondary {
    background: #ffffff;
    border-radius: 30px;
    color: #3366cc;
    border: 1px solid #3366cc;
    margin-left: 1%;
    padding: 0.4% 1%;
    height: 30px;
    text-transform: uppercase;
    cursor: pointer;
}

.btn_disabled {
    background: #bababa;
    border-radius: 30px;
    color: #ffffff;
    margin-left: 1%;
    padding: 0.4% 1%;
    border: none;
    height: 30px;
    text-transform: uppercase;
}

.btn_link {
    color: #3366cc;
}

.btn_link:hover {
    color: #3366cc;
    text-decoration: underline;
    outline-width: 0;
}

.btn_aux {
    font-weight: bold;
    color: #5c6873;
    border: 0px;
    border-radius: 3px;
    background: #f0f3f5;
    min-width: 22px;
    min-height: 25px;
}

.copy-right {
    border-top: 1px solid #dcdcdc;
    height: 20px;
    width: 98%;
    margin: 0 auto;
    padding: 10px 0px 20px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 0.2fr;
    gap: 0px 112px;
    position: relative;
    font-size: 11px;
    color: #212529;
}

::placeholder {
    color: #bababa;
    opacity: 1;
}

.container_app {
    min-height: 70vh;
}

@media only screen and (max-width: 500px) {
    .copy-right {
        border-top: 0px;
        height: 25px;
        width: 98%;
        margin-top: 15px;
        padding: 10px 0px 20px 0px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 10px 0px;
        font-size: 11px;
    }
}

@media only screen and (min-width: 500px) and (max-width: 1080px) {
    .copy-right {
        border-top: 0px;
        height: 25px;
        width: 98%;
        margin: 0 auto;
        padding: 10px 0px 20px 0px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 10px 0px;
        font-size: 11px;
    }
}
