.app-footer{
  justify-content: space-evenly;
  background-color: #3366CC;
  z-index: 8;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 15px 4px 30px 4px;
  position: relative;
}
.app-footer h6 {
  margin: 0 0 10px 0 !important;
}
.app-footer hr{
  height: 1px;
  width: 80%;
  background-color: #ffffff;
}
.app-footer p {
  margin-bottom: 5px;
}
.app-footer span{
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.app-footer-separator-1 {
  width: 1px;
  border: 1px solid white;
  height: 180px;
}
.app-footer-separator{
  width: 1px;
  border: 1px solid white;
  height: 180px;
}
.app-footer-logos{
  width: 20%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.app-footer__logo {
  padding: 0 30px;
  height: inherit;
  min-width: 50px;
  display: flex;
  align-items: center;
  transition: width 0.2s;
}

.app-footer-logo__container {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  text-decoration: none;
}

.app-footer__logo .logo-src {
  height: 60px;
  width: 60px;
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: auto 100%;
  cursor: pointer;
}

.app-footer__logo .logo-potencia-de-la-vida {
  margin: 0 0 6px 0;
  height: 65px;
  width: 150px;
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: 100% 100%;
  cursor: pointer;
}

.app-footer__logo-gov {
  padding: 0 0 20px 10px;
  height: inherit;
  min-width: 50px;
  display: flex;
  align-items: center;
  transition: width 0.2s;
}


.app-footer__logo-gov .logo-gov {
  height: 40px;
  width: 200px;
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: auto 100%;
  cursor: pointer;
}

.app-footer-link{
  display: flex;
  justify-content: center;
  align-items: center;

}
.app-footer-link{
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff  
}

.app-footer a {
  color: #ffffff  
}

.app-footer a:hover {
  color: #ffffff;
  text-decoration: none;
}

.app-footer h6{
  font-weight: 600;
  font-size: 18px;
}

.app-footer-info{
  width: auto;
  padding: 0 20px;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.app-footer-info{
  width: auto;
  padding: 0 30px;
}

.app-footer-logos-social{
  display: flex;
  flex-direction: row;
  margin-top: 45px;
}


.app-footer-logos-social span{
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--white);
}
 
.logo-twitter {
  height: 40px;
  width: 40px;
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: auto 100%;
  margin-bottom: 5px;
  cursor: pointer;
}

.logo-instagram {
  height: 40px;
  width: 40px;
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: auto 100%;
  margin-bottom: 5px;
  cursor: pointer;
}

.logo-facebook {
  height: 40px;
  width: 40px;
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: auto 100%;
  margin-bottom: 5px;
  cursor: pointer;
}

.logo-phone {
  height: 20px;
  width: 30px;
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: auto 100%;
    margin-right: 10px;
}
.app-footer-contact{
  width: auto;
  padding: 0 30px;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.app-footer-contact-info p{
  color: #ffffff;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.app-footer-contact-info b{
  color: #ffffff;
  font-size: 13px;
  margin-right: 15px;
}
.app-footer-info-text {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.app-footer-contact-title.app-footer__logo{
  padding: 0;
}
.app-footer-contact-info a {
  text-decoration: underline !important;
}

@media screen and (max-width: 1200px){
.app-footer__logo{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
.app-footer-logos{
    width: auto;
  }
.logo-twitter {
  margin-bottom: 5px;
  margin-right: 0px;
  }

.logo-instagram {
  margin-bottom: 5px;
  margin-right: 0px;
  }

.logo-facebook {
  margin-bottom: 5px;
  margin-right: 0px;
  }
.app-footer-info-text {
  width: auto;
  padding-left: 20px;
  flex-direction: column;
  align-items: flex-start;
}
.app-footer-info-text > .app-footer-separator {
    display: none;
}
.app-footer-separator-1 {
    height: 410px;
    margin-top: 15px;
}
.app-footer-info {
    margin: 10px 0 30px 0;
}

}
/** Debajo de  1366px **/
@media screen and (max-width: 768px){

  .app-footer {
    flex-direction: column;
  }

  .app-footer-info{
    width: 430px;
    padding: 40px 30px;
  }

  .app-footer__logo span {
    margin-top: 5px;
  }

  .logo-instagram{
    background-position: 50% 50%;
  }
  .app-footer > .app-footer-separator-1 {
    display: none;
  }
  .app-footer-logos-social {
    justify-content: center;
  }
  .app-footer-info-text {
    width: auto;
  }
}

@media screen and (max-width: 500px){

  .app-footer-info{
    max-width: 330px;
    padding: 0 20px 0 0;
  }
  .app-footer-contact{
    max-width: 330px;
    padding: 0 20px 0 0;
  }
}